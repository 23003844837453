
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import { Box, ButtonGroup, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { NextSeo } from "next-seo";
import Fetch from "../../libs/fetch";
import { LISTING_URL } from "../../store/api";
import { HOST, SEARCH_PAGE_ROUTE } from "./../../src/config/routes";
import FilterComponent from "./../../src/components/Filter/Filter";
import screenSize from "./../../src/states/screen";
import { COUNTRIES } from "./../../src/config/constants";
import {
  title,
  description,
  generateAlternateLanguages,
} from "../../src/config/seo";
import ActionCard from "../../src/components/ActionCard";
import { SPORTS_CATEGORY } from "../../src/config/constants";
import WhyUs from "../../src/components/WhyUs";
import AuthButton from "../../src/components/Auth/AuthButton";
import { REVALIDATE } from "../../config/serverConfig";
import SimilarListings from "../../src/components/SimilarListings";
import BlackContainer from "../../src/components/BlackContainer";
import useUsersStatus from "../../src/states/users";

const useStyles = makeStyles((theme) => ({
  searchRoot: {
    backgroundColor: "#E7F5FE", //"#0d63c9",
    width: "100%",
    textTransform: "uppercase",
    margin: 0,
  },
  form: {},
  searchCardHeading: {
    // color: "#FFF",
  },
  listCard: {
    marginTop: theme.spacing(5),
  },
  list: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  listCardHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  searchButton: {
    background: "rgb(217, 60, 35)",
    color: "#FFF",
  },
  buttonGroup: {
    //backgroundColor: "rgba(59, 65, 68, 0.6)",
  },
  buttonStyle: {
    flex: "1",
  },
  buttonStyleRoomRental: {
    flex: "3",
  },
  addBoxHeading: {
    marginBottom: theme.spacing(2),
  },
  addBoxContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formFieldWrapper: {
    marginBottom: theme.spacing(1),
  },
  searchFormWrapper: {
    textAlign: "center",
  },
  formHeading: {
    marginBottom: theme.spacing(5),
  },
}));

export async function getStaticPaths() {
  const paths = [];
  COUNTRIES.forEach((country) => {
    paths.push({
      params: {
        city: country.code,
      },
    });
  });

  return { paths, fallback: "blocking" };
}

 async function _getStaticProps({ params }) {
  const query = {
    city: params.city,
    limit: 4,
  };
  const listingsData = await Fetch({
    url: `${HOST}${LISTING_URL}`,
    method: "GET",
    headers: null,
    queryParams: query,
  });

  return {
    props: {
      listingsData,
      ...query,
      canonicalURL: `${HOST}${params.city}`,
    },
    revalidate: REVALIDATE,
  };
}

function HomePageComponent({ city, listingsData }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoggesIn = useUsersStatus();
  const router = useRouter();
  const isDesktop = screenSize();
  const { t } = useTranslation("common");

  const [filterValues, setFilterValues] = useState({
    city: city,
  });

  const setSeletedType = (value) => {
    setFilterValues({
      ...filterValues,
      type: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { autocompleteData, ...filterVal } = filterValues;
    const filter = {};
    for (let i in filterVal) {
      if (filterVal[i]) {
        filter[i] = filterVal[i];
      }
    }

    const poiId = [];
    const propertyId = [];
    const streetId = [];

    router.push({
      pathname: SEARCH_PAGE_ROUTE,
      query: {
        poiId,
        propertyId,
        streetId,
        ...filter,
      },
    });
  };

  const titleSeo = t(title.countryHome, { city });
  const descriptionSeo = t(description.countryHome, { city });

  let cityLabel = city;
  if (city) {
    const selectedCountry = COUNTRIES.find(({ code }) => code === city);
    if (selectedCountry && selectedCountry.label) {
      cityLabel = selectedCountry.label;
    }
  }

  return (
    <Container maxWidth={false} disableGutters={true}>
      <NextSeo
        title={titleSeo}
        description={descriptionSeo}
        canonical={`${HOST}/${city}`}
        languageAlternates={generateAlternateLanguages(`/${city}`)}
        openGraph={{
          url: `${HOST}/${city}`,
          type: "website",
          title: titleSeo,
          description: descriptionSeo,
          images: [
            {
              url: `${HOST}/logo.png`,
              width: 200,
              height: 200,
              alt: "Find a Sports Partner",
            },
          ],
        }}
      />
      <Box my={1} p={3} className={classes.searchRoot} disableGutters={true}>
        <Container maxWidth="md" disableGutters={isDesktop ? false : true}>
          <h1 style={{ textIndent: -5000, height: 0, margin: 0 }}>
            {t("find_a_sports_partner")}
          </h1>
          <form
            onSubmit={handleFormSubmit}
            className={classes.form}
            action="/search"
            method="get"
            target="_top"
          >
            <Grid
              container
              xs={12}
              justify="center"
              className={classes.searchFormWrapper}
            >
              <Grid item xs={12} sm={12} className={classes.formHeading}>
                <Typography
                  className={classes.searchCardHeading}
                  variant="h4"
                  component="h2"
                >
                  {t("find_a_sports_partner_in_city", {
                    city,
                  })}
                </Typography>
              </Grid>
              <AuthButton label={t("find_your_match")}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.formFieldWrapper}
                  disableGutters={true}
                >
                  <FilterComponent
                    hideListingType={true}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                  />
                </Grid>
                <Hidden smUp>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className={classes.formFieldWrapper}
                    disableGutters={true}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      color="primary"
                      disabled={!filterValues.city}
                    >
                      {t("search")}
                    </Button>
                  </Grid>
                </Hidden>
              </AuthButton>
            </Grid>
          </form>
        </Container>
      </Box>

      <Container maxWidth="md">
        <Grid container xs={12} className={classes.listCard}>
          <Grid item xs={12}>
            <Typography
              className={classes.listCardHeading}
              variant="h4"
              component="h3"
            >
              {t("find_sports_partner_in_citi_by_sport", {
                city: cityLabel,
              })}
            </Typography>
          </Grid>
          <Grid container xs={12}>
            {SPORTS_CATEGORY.map((category) => {
              return (
                <Grid item xs={6} sm={4}>
                  <ActionCard
                    href={`${HOST}/${filterValues.city}/${category.code}`}
                    label={category.label}
                    title={t("find_sports_partner_in_citi_by_sport_value", {
                      city: cityLabel,
                      sport: category.label,
                    })}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>

      {!isLoggesIn && (
        <BlackContainer style={{ marginTop: "24px" }}>
          <Container maxWidth="md">
            <Grid
              container
              x={12}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={9}>
                <Typography
                  className={classes.listCardHeading}
                  variant="h4"
                  component="h2"
                >
                  {t("looking_for_a_sport_partner_by_city", {
                    city,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <AuthButton label={t("sign_up_now_and_search")} />
              </Grid>
            </Grid>
          </Container>
        </BlackContainer>
      )}

      <Container maxWidth="md">
        {listingsData && listingsData.listings && listingsData.listings.length ? (
          <Grid container xs={12} className={classes.list}>
            <Grid item xs={12}>
              <SimilarListings
                list={listingsData.listings}
                title={t(
                  "in_the_city_there_are_many_people_looking_for_a_sports_partner",
                  {
                    city: cityLabel,
                  }
                )}
              />
            </Grid>
          </Grid>
        ) : null}
      </Container>

      {!isLoggesIn && (
        <BlackContainer>
          <Container maxWidth="md">
            <Grid container xs={12} alignItems="center">
              <Grid item xs={12} sm={10}>
                <Typography
                  className={classes.listCardHeading}
                  variant="h4"
                  component="h3"
                >
                  {t("be_active_together_with_findsportspartner")}
                </Typography>

                <Typography
                  className={classes.listCardHeading}
                  variant="h5"
                  component="p"
                >
                  {t("find_sport_buddy_near_you")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <AuthButton label={t("sign_up")} />
              </Grid>
            </Grid>
          </Container>
        </BlackContainer>
      )}
      <Container maxWidth="md">
        <WhyUs />
      </Container>
    </Container>
  );
}

export default HomePageComponent;

// export const config = { amp: "hybrid" };


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[city]/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  