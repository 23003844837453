import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ShareIcon from "@material-ui/icons/Share";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import useTranslation from "next-translate/useTranslation";
import isDesktopScreen from "./../../states/isDesktopScreen";
import {
  SPORTS_CATEGORY,
  PREFERRED_GENDER,
  PREFERRED_AGE_GROUP,
  COUNTRIES,
  GENDER,
  countrySpecificConfig,
} from "../../config/constants";
import useStyles from "./style";

export default function NewCard({
  description,
  sportCategory,
  district,
  preferredGender,
  city,
  preferrdAgeGroup,
  notSameAsPhone,
  phone = "",
  email,
  whatsAppNumber = "",
  picture,
  name,
  phoneCountry = "",
  whatsappPhoneCountry = "",
  age = "",
  gender,
  contactByPhone,
  media = [],
  noActions = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const cityName = city
    ? COUNTRIES.find((item) => item.code === city)?.label
    : city;

  const districts =
    countrySpecificConfig[city] && countrySpecificConfig[city].DISTRICT
      ? countrySpecificConfig[city].DISTRICT
      : [];

  const districtName = district
    ? districts.find((item) => item.code === district)?.label
    : district;

  const sportCategoryList =
    sportCategory && sportCategory.length
      ? sportCategory.map(
          (sport) =>
            SPORTS_CATEGORY.find((category) => category.code === sport)?.label
        )
      : [];
  const preferrdAgeGroupName =
    preferrdAgeGroup && preferrdAgeGroup.length
      ? preferrdAgeGroup.map(
          (ageGroupCategory) =>
            PREFERRED_AGE_GROUP.find(
              (ageGroup) => ageGroup.code === ageGroupCategory
            )?.label
        )
      : null;
  const preferredGenderName = preferredGender
    ? PREFERRED_GENDER.find((item) => item.code === preferredGender)
    : null;

  const genderLabel = gender
    ? GENDER.find((item) => item.code === gender)
    : null;

  const chipProps = {};
  let chipVariant = "default";

  const whatsAppPhoneNumber = notSameAsPhone ? whatsAppNumber : phone;
  let whatsAppPhoneNumberCountryCode = notSameAsPhone
    ? whatsappPhoneCountry
    : phoneCountry;

  whatsAppPhoneNumberCountryCode = whatsAppPhoneNumberCountryCode.replace(
    "+",
    ""
  );

  const currentYear = new Date().getFullYear();
  age = currentYear - Number(age);

  picture = media && media?.length ? media[0]?.url : picture;

  return (
    <Card className={classes.root} elevation={2}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={picture || `/images/${sportCategory[0]}.png`}
          title={sportCategory[0]}
        />
        <CardContent>
          <Typography gutterBottom variant="body1" component="h2">
            {name}
          </Typography>
          <Typography gutterBottom variant="body1" component="h3">
            {genderLabel ? `${genderLabel.label}/${age}` : age} {cityName}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            noWrap
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />

          <Box p={0} {...chipProps}>
            {sportCategoryList.slice(0, 2).map((item) => (
              <Chip
                size="small"
                variant={chipVariant}
                className={classes.chip}
                label={t(item)}
                color="secondary"
              />
            ))}

            {preferrdAgeGroupName.slice(0, 2).map((item) => (
              <Chip
                size="small"
                variant={chipVariant}
                className={classes.chip}
                label={t(item)}
                color="secondary"
              />
            ))}
          </Box>
        </CardContent>
      </CardActionArea>
      {!noActions ? (
        <CardActions>
          {contactByPhone && whatsAppPhoneNumber && (
            <Button
              fullWidth
              component="a"
              color="secondary"
              variant="outlined"
              target="__blank"
              href={`https://api.whatsapp.com/send?phone=${whatsAppPhoneNumberCountryCode}${whatsAppPhoneNumber}&text=${encodeURIComponent(
                ""
              )}`}
              startIcon={<WhatsAppIcon />}
            >
              {t("WhatsApp")}
            </Button>
          )}
          {email && (
            <Button
              fullWidth
              component="a"
              color="secondary"
              variant="outlined"
              href={`mailto:${email}?subject=''&body=${encodeURIComponent("")}`}
              startIcon={<EmailOutlinedIcon />}
            >
              {t("Email")}
            </Button>
          )}
          {/* <Button size="small" color="primary">
          Learn More
        </Button> */}
        </CardActions>
      ) : null}
    </Card>
  );
}
