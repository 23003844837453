import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SearchCard from "../SearchCard/newCard";

const SimilarListings = ({ list, title }) => {

  return (
    <Grid container xs={12} sm={12} md={12}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          variant="h4"
          component="h2"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container xs={12} sm={12} md={12}>
          {list.map((listing) => (
            <Grid item xs={12} sm={3} md={4} key={listing._id}>
              <SearchCard {...listing} noActions  />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SimilarListings;
