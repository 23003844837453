import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
  media: {
    height: 250,
  },
  slide: {
    position: "relative",
    background: "#F0F0F0",
    minHeight: "150px",
  },
  svg: {
    width: "20px",
    heigt: "20px",
  },
  cardInfoParent: {
    display: "flex",
    width: "100%",
  },
  itemInfo: {
    display: "flex",
    alignItem: "center",
    marginRight: theme.spacing(1),
  },
  favourite: {
    color: theme.palette.primary.main,
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  imageSlide: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

export default useStyles;
